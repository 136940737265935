import React, { useState } from 'react'
import './Navigation.css'
import { useNavigate } from 'react-router-dom'
function Navigation() {
    const [mobileScreen, setMobileScreen] = useState(false)

    return (
        <div className='navigation-section'>
            <div className="navigation">
                <div className="logo">
                    {/* <h3>NX<span style={{ color: 'orangered', fontSize: '25px' }}>T</span>ECHMIND</h3> */}
                    <img src={require('./png files of nxt logo-01.png')} width={300} alt="" />
                </div>
                <div className="link-container">
                    <div className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/#about">About</a> </div>
                    <div className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/#services">Solutions</a> </div>
                    <div className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/#values">Mission & Vision</a> </div>
                    <div className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/contact">Contact</a> </div>
                </div>
                <i onClick={(e) => setMobileScreen(!mobileScreen)} class="fa-solid fa-bars"></i>


            </div>
            {
                mobileScreen ?
                    <>
                        <div className="link-container-small-screen">
                            <div onClick={()=>setMobileScreen(!mobileScreen)} className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/#about">About</a> </div>
                            <div onClick={()=>setMobileScreen(!mobileScreen)} className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/#services">Solutions</a> </div>
                            <div onClick={()=>setMobileScreen(!mobileScreen)} className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/#values">Mission & Vision</a> </div>
                            <div onClick={()=>setMobileScreen(!mobileScreen)} className="link"><a style={{ color: 'white', textDecoration: 'none' }} href="/contact">Contact</a> </div>
                        </div>
                    </> :
                    <>

                    </>
            }

        </div>
    )
}

export default Navigation