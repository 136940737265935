import React from 'react'
import About from './About'
import Header from './Header'
import Services from './Services'
import Values from './Values'

function Home() {
  return (
    <div className='home-section'>
        {/* <br /><br /> */}
        <Header/>
        <About/>
        <Values/>
        <Services/>
    </div>
  )
}

export default Home