import React from 'react'

function Software() {
  return (
    <div className='infra-section'>
      <div className="infra">
        <br />
        <div className="infra-heading">
          <h2>We specialise in creating scalable
            <span> Software and Web Applications</span>
          </h2>
        </div>

        <div className="infra-para">

          <img src={require('./software.jpg')} alt="" />
          <p>
            With an expert team that really knows what it’s doing, our company provides the best and customized software solution for your
            business needs
          </p>

          <p>
            <span style={{ color: 'rgb(27, 0, 125)', fontWeight: 'bolder' }}>Tech Stack</span> Used
          </p>
          <p>
            <div class="container">
              <div class="column">
                <h2>Frontend</h2>
                <ul>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>Javascript</li>
                  <li>React JS</li>
                  <li>Next JS</li>
                  <li>Electron</li>
                  <li>Nest</li>
                </ul>
              </div>
              <div class="column">
                <h2>Backend</h2>
                <ul>
                  <li>Node JS</li>
                  <li>Express JS</li>
                  <li>Python</li>
                  <li>Java</li>
                  <li>Springboot</li>
                  <li>Django</li>
                </ul>
              </div>
              <div class="column">
                <h2>Database</h2>
                <ul>
                <li>MYSQL</li>
                <li>MongoDB</li>
                <li>PLSQL</li>
                <li>Firebase</li>
                </ul>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Software