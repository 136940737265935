import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <div className='footer-section'>
        <div className="footer">
            <div className="footer-heading">
                <h2>Ready? Get Started!</h2>
            </div>
            <div className="footer-subhead-1">
                <h3>Have Questions, Ready To Take Action ?</h3>
            </div>
            <div className="footer-para">
                <a style={{color: 'white'}} href="/contact">Contact Us</a> ASAP And Let Us Take Your Business To The High Tech Level
            </div>
        </div>
    </div>
  )
}

export default Footer