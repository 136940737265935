import React from 'react'
import './Home.css'


function Values() {
    return (
        <div className='values-section'>
            <div className="values">
                <div className="values-heading">
                    <h2>Why Should You <span>Pick Us ?</span>  </h2>
                </div>
                <div className="values-para">
                    <p>
                        <span>3 words</span> , <span>3 pillars</span>  in our business, <span>3 keys </span>
                         to understand why our customers remain so
                        loyal to us, and why we will be your ideal
                        partner.
                    </p>
                </div>

                <div className="values-cards" >
                    <div className="value-card">
                        <div className="card-head">
                            <div className="img">
                                <img src="https://img.icons8.com/2233ee/suitcase" alt="" />
                            </div>
                            <div className="card-heading">
                                <h3>Professionalism</h3>
                            </div>
                        </div>
                        <div className="card-para">
                            <p>
                                Working in a professional manner is what our experts
                                do best, which allows us to always stay ahead of
                                the game
                            </p>
                        </div>
                    </div>
                    <div className="value-card">
                        <div className="card-head">
                            <div className="img">
                                <img src="https://img.icons8.com/2233ee/helping-hand" alt="" />
                            </div>
                            <div className="card-heading">
                                <h3>Accompaniment </h3>
                            </div>
                        </div>
                        <div className="card-para">
                            <p>
                                Beyond our professionalism, our team will always be
                                at your disposal to answer all your questions in a
                                regular way, and as fast as detailed.

                            </p>
                        </div>
                    </div>
                    <div className="value-card">
                    <div className="card-head">
                            <div className="img">
                                <img src="https://img.icons8.com/2233ee/mirror" alt="" />
                            </div>
                            <div className="card-heading">
                                <h3>Transparency </h3>
                            </div>
                        </div>
                        <div className="card-para">
                            <p>
                            Since it is your guarantee of trust, transparency is 
                            at the heart of everything our team does. With us, 
                            there are no big detours and no nasty surprises. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Values