import React from 'react'
import './Services.css'

function Cloud() {
  return (
    <div className='infra-section'>
        <div className="infra">
            <br /> 
            <div className="infra-heading">
                <h2>
                <span>Cloud Computing</span>  Is More Than A Just Buzzword 
                </h2>
            </div>

            <div className="infra-para">
                <img src={require('./cloud-computing.jpg')}  alt="" />
                <p>
                Nowadays, Cloud Computing has become the cornerstone 
                for the digital transformation of all companies. 
                However, time, manpower and money can all be saved 
                when cloud-based solutions are used
                </p>

                <p>
                Fully aware of these stakes, our team builds 
                our cloud solutions on a deep understanding of 
                how the cloud can connect people, technology, 
                processes and data
                </p>

                <p>
                Because we know perfectly that every business 
                needs a different cloud strategy, we'll help 
                you determine what's right for you, while 
                building solutions with operational, industry 
                and technology expertise to help you accelerate 
                your journey  to the cloud, make it a source of 
                success, and much more, keep customers satisfied 
                and employees productive
                </p>
            </div>
        </div>
    </div>
  )
}

export default Cloud