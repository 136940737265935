import React from 'react'
import { useNavigate } from 'react-router-dom'
function Services() {
    const navigate = useNavigate();
  return (
    <div className='services-section' id='services'>
        <div className="services">
            <div className="services-heading">
                <h2><span>Solutions</span>  We Provide</h2>
            </div>

            <div className="services-cards">
                    <div onClick={()=>navigate('/infra')} className="service-card">
                        <div className="service-card-img">
                            <img src="https://img.icons8.com/nolan/64/web" alt="" srcset="" />
                        </div>
                        <div className="service-card-heading">
                            <h3><a href="/infra">IT Infrastructure Solutions</a> </h3>
                        </div>
                    </div>
                    <div onClick={()=>navigate('/cloud')} className="service-card">
                        <div className="service-card-img">
                            <img src="https://img.icons8.com/nolan/64/cloud" alt="" srcset="" />
                        </div>
                        <div className="service-card-heading">
                            <h3><a href="/cloud">Cloud Computing</a></h3>
                        </div>
                    </div>
                    <div onClick={()=>navigate('/cyber')} className="service-card">
                        <div className="service-card-img">
                            <img src="https://img.icons8.com/nolan/64/cyber-security" alt="" srcset="" />
                        </div>
                        <div className="service-card-heading">
                            <h3><a href="/cyber">IT Security</a></h3>
                        </div>
                    </div>
                    <div onClick={()=>navigate('/energy')} className="service-card">
                        <div className="service-card-img">
                            <img src="https://img.icons8.com/nolan/64/solar-energy" alt="" srcset="" />
                        </div>
                        <div className="service-card-heading">
                            <h3><a href="/energy">Energy And Power</a></h3>
                        </div>
                    </div>
                    <div onClick={()=>navigate('/software')} className="service-card">
                        <div className="service-card-img">
                            <img src="https://img.icons8.com/nolan/64/web" alt="" srcset="" />
                        </div>
                        <div className="service-card-heading">
                            <h3><a href="/software">Software Solutions</a></h3>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Services