import React from 'react'
import './Services.css'

function Infra() {
  return (
    <div className='infra-section'>
            <div className="infra">
                <br />
                <div className="infra-heading">
                    <h2>
                    <span>IT Infrastructure Solutions</span>  To Escalate Companies Technological Landscape
                    </h2>
                </div>
                <div className="infra-para">
                    <img src={require('./IT Infra.png')} alt="" />
                    <p>
                    Just like countries rely on physical infrastructure to 
                    provide citizens with access to fundamental 
                    resources such as electrical grids and bridges, 
                    companies rely on IT Infrastructure to let their 
                    members use technology effectively and do their 
                    jobs as professionally as possible
                    </p>
                    <p>
                    It is not for nothing that we talk about IT Infrastructure. 
                    The components of the latter work together 
                    to create efficient processes, effective 
                    communication, and productive workflow within 
                    the company
                    </p>
                    <p>
                    Because the implementation of an IT infrastructure 
                    requires a deep expertise and multiple techniques
                    , NxTechMind puts at your disposal their professionals 
                    and senior profiles who have 360° expertise in 
                    the field and who will implement all their 
                    knowledge to help you enhance your operational 
                    efficiencies, boost performance, increase productivity
                    and accelerate the time to market with reduced cost
                    </p>
                </div>
            </div>

        </div>
  )
}

export default Infra