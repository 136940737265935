import './App.css';
import Footer from './Components/Constants/Footer/Footer';
import Navigation from './Components/Constants/Navigation/Navigation';
import Home from './Components/Home/Home';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Infra from './Components/Services/Infra';
import Cloud from './Components/Services/Cloud';
import Security from './Components/Services/Security';
import Energy from './Components/Services/Energy';
import Contact from './Components/Contact/Contact';
import Software from './Components/Services/Software';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/infra' element = {<Infra/>}/>
          <Route exact path='/cloud' element={<Cloud/>}/>
          <Route exact path='/cyber' element={<Security/>}/>
          <Route exact path='/energy' element={<Energy/>}/>
          <Route exact path='/software' element={<Software/>}/>
          <Route exact path='/contact' element={<Contact/>}/>
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
