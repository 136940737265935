import React from 'react'
import './Home.css'
function About() {
    return (
        <div className='about-section' id='about'>
            <div className="about">
                <div className="about-heading">
                    <h2>Over <span>20 Years Of Experience</span>  In The IT Industry </h2>
                </div>
                <div className="about-para">
                    <p>
                        <span >Nx</span><span style={{ color: 'orangered' }}>Tech</span><span>Mind</span>  is an IT development services provider
                        that knows how to adapt to all your needs in order
                        to accompany you from your first step, not only to
                        make you benefit from services exceeding the highest
                        industry standards, but also to help you move your
                        business to the next level with IT solutions that
                        satisfy the needs of today and unlock the opportunities
                        of tomorrow.

                    </p>
                </div>
                <div className="our-mission" id='values'>
                    <h2>Our <span style={{color: 'rgb(27, 98, 160)', textAlign: 'left'}}>Mission</span> </h2>
                    <p>
                    Our mission is to deliver scalable and 
                    innovative IT solutions which add value 
                    to each business, of small or large size.
                    </p>
                </div>

                <div className="our-vision">
                    <h2>Our <span style={{color: 'rgb(27, 98, 160)', textAlign: 'left'}}>Vision</span> </h2>
                    <p>
                        If you are here, it is surely because you want to know why we do that we do.
                    </p>
                    <p>
                        Without further ado, it is for these two reasons:
                    </p>
                    <p>
                        <ul>
                            <li>To become the industry leader in the Indian market.</li>
                            <li>To provide peace of mind to the founders of all businesses.</li>
                        </ul>
                    </p>
                </div>

                <div className="our-team">
                    <h2>Our <span style={{color: 'rgb(27, 98, 160)', textAlign: 'left'}}>Team</span> </h2>
                    <h3>Experts You can count on</h3>
                    <p>
                       <span>The 20 years on experience</span>  and the diversity of 
                        projects on which our professionals have worked have made <span>efficiency, flexibility
                        and speed</span>  their main strengths and our source of success.
                    </p>
                </div>
            </div>
            
        </div>
    )
}

export default About