import {React, useState} from 'react'
import axios from 'axios';
import './Contact.css'
function Contact() {

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [service, setService] = useState('');
    const [query, setQuery] = useState('');

    const [message, setMessage] = useState('');

    

    const sendMessage = (e)=>{
        e.preventDefault();
        axios.post('https://nxtechmind.com/service_selected.php', {email, phone, service, query})
        .then((res)=>{
            setMessage('Thank You For Contacting Us');
            setEmail('')
            setService('')
            setPhone('')
            setQuery('')
            
        })
        .catch((err)=>{
            setMessage('Something Went Wrong. Please contact through our email ID sales@nxtechmind.com')
            setEmail('')
            setService('')
            setPhone('')
            setQuery('')
        })

        axios.post('https://nxtechmind.com/sendEmail.php', {email, phone, service, query})
        .then((res)=>{
            console.log(res.data)
        })
        .catch((err)=>{
            console.log(err)
        })
        
    }



    return (
        <div className='contact-section'>
            <div className="contact">
                <div className="contact-heading">
                    <h1>Submit Your Information and Required Service Here</h1>
                </div>
                <div className="contact-details">
                    <div className="detail-cards">
                        <div className="location">
                            <div className="pic-location">
                                <img src="https://img.icons8.com/nolan/64/map" alt="" />
                            </div>
                            <div className="address">
                                WeWork Seawoods Grand Central, <br /> 10th Floor, Tower 1, Sector 40, Seawoods Railway Station <br /> Nerul Node, Navi Mumbai, MH 400706
                            </div>
                        </div>
                    </div>
                    <div className="detail-cards">
                        <div className="location">
                            <div className="pic-location">
                            <img src="https://img.icons8.com/nolan/64/email" alt="" />
                            </div>
                            <div  className="address"><a style={{color: 'black', textDecoration: 'none'}} href="mailto:sales@nxtechmind.com">sales@nxtechmind.com</a><br /> <br />02248808542</div>
                            {/* <div className="contact">02248808542</div> */}
                        </div>
                    </div>
                    
                    
                </div>
                <div className="contact-form">
                    <div className="form">
                        <form onSubmit={sendMessage}>
                            <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter Your Email' type="email" name='email' /> <br /> <br />
                            <input value={phone} onChange={(e)=>setPhone(e.target.value)}  type="text" name='phone' placeholder='Enter Your Phone Number' /> <br /><br />
                            <input value={service} onChange={(e)=>setService(e.target.value)} name='service' placeholder='Select Service' type="text" list='optionlist' /> <br /> <br />
                            <datalist id='optionlist'>
                                <option value="IT Infrastructure Solutions"></option>
                                <option value="Cloud Computing"></option>
                                <option value="IT Security"></option>
                                <option value="Energy and Power"></option>
                                <option value="Software Solutions"></option>
                            </datalist> 
                            <input value={query} onChange={(e)=>setQuery(e.target.value)} placeholder='Write your query' name='query' type="text" style={{height: '100px'}} /> <br /><br />
                            <input type="submit" />
                        </form>
                    </div>
                </div>
            </div>

            <>{message}</>
        </div>
    )
}

export default Contact