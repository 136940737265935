import React from 'react'
import './Home.css'
function Header() {
    return (
        <div className='header-section'>
            <div className="header">
                <div className="heading">
                    <h3>Get Your Business Online With </h3>
                    <h1>NXTECHMIND</h1>
                    <p>
                        IT Solutions No Longer Hold Any Secrets For Us
                    </p>
                    <div className="btn-contact">
                        <a style={{color: 'white', textDecoration: 'none'}} href="/contact">Get In Touch With Us</a> 
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Header