import React from 'react'
import './Services.css'
function Energy() {
  return (
    <div className='infra-section'>
        <div className="infra">
            <br /> 
            <div className="infra-heading">
                <h2>What Could Be Better Than Moving To
                <span> Power And Energy</span>  With Experts Who Really Know What They Are Doing?  
                </h2>
            </div>

            <div className="infra-para">

                <img src={require('./energy.jpg')} alt="" />
                <p>
                With an expert team that really knows what it’s doing, our company provides the latest in energy efficiency solutions for interrupted operations in your organization. 

                </p>

                <p>
                Whether you are a small or large business, we are committed to providing you with a full range of customized services to solve your problems and perfectly meet your needs and requirements. 

                </p>

                
            </div>
        </div>
    </div>
  )
}

export default Energy