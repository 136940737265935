import React from 'react'
import './Services.css'
function Security() {
  return (
    <div className='infra-section'>
        <div className="infra">
            <br /> 
            <div className="infra-heading">
                <h2>
                <span>IT Security</span>  Is No A Small Task - And You Shouldn’t Have To Do It Alone  
                </h2>
            </div>

            <div className="infra-para">

                <img src={require('./cyber-security-2296269_1920.jpg')} alt="" />
                <p>
                The importance of IT security in companies cannot 
                be overstated. It is critical that organizations 
                take the needed precautions to safeguard their 
                sensitive information from unauthorized access, 
                data breaches,and other disruptive data security 
                threats to business and consumer data. 


                </p>

                <p>
                Because IT security can only be effective if it is 
                driven by experts who believe in what they 
                are doing, we will not fail to put at your 
                disposal the professionals of the field who 
                will put your business at the heart of their 
                work to strengthen it with operational resilience, 
                flawless data privacy, and robust protection 
                against cyber threats
                </p>
            </div>
        </div>
    </div>
  )
}

export default Security